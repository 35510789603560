<template>
  <div
    :class="isShow"
    style="margin-top: 52px;"
  >
    <div class="header">灭菌可信溯源</div>

    <h1 class="main-title">器械信息</h1>
    <section class="sec">
      <div class="sec-fields">
        <p class="sec-field">器械名称</p>
        <p class="sec-value">{{ instrumentName || '通用器械' }}</p>
      </div>
      <!-- <div class="sec-fields">
        <p class="sec-field">器械数量</p>
        <p class="sec-value">{{ instrumentCount|| '1' }}</p>
      </div> -->
      <div class="sec-fields">
        <p class="sec-field">归属医院</p>
        <p class="sec-value">{{ hospitalName }}</p>
      </div>
    </section>

    <h1 class="main-title">灭菌信息</h1>
    <section class="sec">
      <div class="sec-fields">
        <p class="sec-field">灭菌方法</p>
        <p class="sec-value">{{ jobMethod }}</p>
      </div>
      <div class="sec-fields">
        <p class="sec-field lh">灭菌时间</p>
        <p class="sec-value font-size-s">
          {{ jobStartTime || "无" }}<br />
          {{ jobEndTime || "无" }}
        </p>
      </div>
      <div class="sec-fields">
        <p class="sec-field">灭菌有效期至</p>
        <p class="sec-value font-size-s valid">{{ jobValidTime || '见包装失效日期' }}</p>
      </div>
      <div class="sec-fields">
        <p class="sec-field">操作人员</p>
        <p class="sec-value">{{ operator }}</p>
      </div>
    </section>

    <h1 class="main-title">灭菌存证拍摄</h1>
    <section class="sec">
      <div class="sec-fields" style="display: block">
        <img
        style="padding: 5px;"
          v-for="url in jobImage"
          :key="url"
          :src="url"
          class="sec-img"
          @click="handleImage(url)"
          alt=""
        />
        <p style="color: #999" v-if="!jobImage.length">暂无</p>
      </div>
    </section>

    <h1 class="main-title">灭菌评定</h1>
    <section class="sec">
      <div class="sec-fields">
        <p class="sec-field">灭菌综合检测</p>
        <p class="sec-value valid">{{ result }}</p>
      </div>
      <div class="sec-fields">
        <p class="sec-field">已溯源次数</p>
        <p class="sec-value valid">{{ traceToCount }}</p>
      </div>
    </section>
    <!--  简易图片预览 -->
    <transition name="fade">
      <div
        class="imageBox"
        v-show="imageVisible"
        @click="closeImage"
      >
        <img
          class="imageBox-img"
          :src="imageVisible"
          alt=""
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { getQRCodeInfo } from "../api/index";
import { getSearchParams } from "../utils/util";

export default {
  data() {
    return {
      // 显示
      isShow: false,

      // 显示画廊
      imageVisible: false,

      // 业务字段
      instrumentName: "",
      instrumentCount: "",
      hospitalName: "",

      jobMethod: "",
      jobStartTime: "",
      jobEndTime: "",
      jobValidTime: "",
      operator: "",

      jobImage: [],
      result: "",
      traceToCount: "",

      // mockJobImage: require('./assets/imgs/mjb.jpg')
    };
  },
  async mounted() {
    await this.initData();
    this.isShow = true;
  },
  methods: {
    async initData() {
      // 获取地址的参数
      const { id } = getSearchParams();
      // 获取可信溯源数据
      const rs = await getQRCodeInfo(id);

      // 直接遍历入数据
      Object.keys(rs).forEach((key) => {
        this[key] = rs[key];
      });
    },
    handleImage(url) {
      this.imageVisible = url;
    },
    closeImage() {
      this.imageVisible = false;
    },
  },
};
</script>

<style lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$font-size-l: 18px;
$font-size: 16px;
$font-size-s: 14px;

$font-color: #333;
$font-color-l: #a1a1a1;

$header-height: 52px;
$sec-pad: 5px 10px;
$border-radius: 5px;

* {
  padding: 0;
  margin: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #f7f7f7;
}

.font-size-s {
  font-size: $font-size-s;
}

.lh {
  line-height: 1.5;
}

.app {
  padding: $header-height 0 25px;
}

.header {
  background: url("../assets/imgs/navbar-bcg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: $header-height;
  @include center;
  color: #fff;
  font-size: $font-size-l;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.main-title {
  font-size: $font-size;
  font-size: $font-color;
  padding: 10px 10px 5px;
}

.sec {
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin: $sec-pad;
  border-radius: $border-radius;
  overflow: hidden;
  &-fields {
    padding: 10px;
    @include spaceBetween;
    border-bottom: 1px solid #eee;
    background: #fff;

    &:last-child {
      border-color: transparent;
    }

  }
  &-field {
    color: $font-color;
  }
  &-value {
    color: $font-color-l;
    &.valid {
      color: #2cbb80;
      font-weight: bold;
    }
  }
  &-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

.hide {
  display: none;
}

.imageBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  @include center;
  &-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 500ms;
}
</style>
