
/**
 * 获取 window.location.search 中的参数
 */
 export function getSearchParams() {
  const arg = window.location.search.slice(1);
  const argArr = arg.split("&");
  const params = {};
  argArr.forEach((str) => {
    const [key, value] = str.split("=");
    params[key] = value;
  });
  return params;
}

/**
 * 获取 window.location.pathname 中的参数
 */
 export function getPathParams() {
  const arg = window.location.pathname.split('/').pop();
  return arg;
}

/**
 * 延长时间 (按天)
 * @param {string} date 当前日期 // 2021-08-30 14:15
 * @param {number} day 延长天数
 */
export function mmddyyDelay(date, day) {
  const [prefix, latter = ""] = date.split(" ");
  // 当前时间戳
  let timestamp = new Date(prefix).getTime();
  // 延长 day 天
  timestamp += day * 24 * 60 * 60 * 1000;

  // 获取延长后的年月日
  let curDate = new Date(timestamp).toLocaleDateString();
  // 斜杠换成段横杠
  curDate = curDate.replace(/\//g, "-");
  // 月日补零
  curDate = curDate
    .split("-")
    .map((i) => i.padStart(2, "0"))
    .join("-");
  return curDate + " " + latter;
}
