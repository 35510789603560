/**
 * @description api 接口集合
 */

import { mmddyyDelay } from "../utils/util";

const base = "https://kxmj.wskxpt.com:18080";
const baseApi = base + "/api";
const baseFile = base + "/files";

const urls = {
  getQRCodeInfo: baseApi + "/trace",
};

/**
 * 返回资源真实地址
 * @param {string} relaUrl 资源相对地址
 */
export function genFileUrl(relaUrl) {
  if (!relaUrl) {
    return ''
  }
  return baseFile + "/" + relaUrl;
}

/**
 * fetch 二阶封装
 */
export async function fetch2(url, opts = {}) {
  let fetchData = await fetch(url, opts);

  fetchData = await fetchData.json();

  // 中间件
  const { code, data } = fetchData;
  if (code !== 200) {
    throw new Error(fetchData);
  } else {
    return data;
  }
}

/**
 * 获取可信溯源数据
 * @param {number} qrcodeId 二维码id
 */
export async function getQRCodeInfo(qrcodeId) {
  let rs = await fetch2(`${urls.getQRCodeInfo}/${qrcodeId}`);

  // 特殊字段映射
  let { jobStartTime, jobEndTime, valid, jobImage, id } = rs;

  rs.jobImage = (rs.jobImage || '').split(',').filter(i => i).map(i => genFileUrl(i));

  console.log(rs.jobImage)
  // rs.instrumentCount =  '1个';

  // 测试用 直接默认日期
  // if (!jobStartTime) {
  //   rs.jobStartTime = "2021/10/14 10:58";
  // }

  // if (!jobEndTime) {
  //   rs.jobEndTime = "2021/10/14 11:58";
  // }
  if (jobEndTime && valid) {
    rs.jobValidTime = mmddyyDelay(rs.jobEndTime, valid);
  }



  return rs
}
